/* src/Login.css */

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

.login-container {
  display: flex;
  height: 100vh;
}

.login-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
}

.login-form h1 {
  margin-bottom: 20px;
  margin-right: 120px;
}
.login-form img {
  margin-right: 370px;
}

.input-group {
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
  position: relative;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input {
  width: 402px;
  padding: 12px 12px 12px 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-group .material-icons {
  position: absolute;
  top: 30px;
  left: 10px;
  color: black; /* Change icon color to black */
}

.forgot-password {
  text-align: right;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.forgot-password a {
  color: #007bff;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

.login-form button {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}

.terms {
  margin-top: 20px;
  font-size: 12px;
  text-align: center;
}

.terms a {
  color: #007bff;
  text-decoration: none;
}

.terms a:hover {
  text-decoration: underline;
}

.footer {
  position: absolute;
  bottom: -20px;
  width: 100%;
  padding: 10px 0;
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  box-sizing: border-box;
  padding: 0 20px;
}

.footer p {
  margin: 0;
}

.footer .footer-links {
  display: flex;
  align-items: center;
}

.footer .footer-links a {
  color: #171717;
  text-decoration: none;
  margin: 0 10px;
  position: relative;
}

.footer .footer-links a::after {
  content: "•";
  color: #000;
  margin-left: 10px;
}

.footer .footer-links a:last-child::after {
  content: "";
}
